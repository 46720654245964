import React from 'react';

import SculptureCard from './SculptureCard'

export default function SculpturesList( props ) {

	const { sculptures } = props

	return (
		<div className="sculptures-list__wrapper wrapper">
			<div className="sculptures-list">
				{ sculptures.map( sculpture => (
					<SculptureCard key={ sculpture.id } sculpture={ sculpture } />
				) ) }
			</div>
		</div>
	);
}

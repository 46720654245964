import React from 'react';
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export default function SculptureCard( props ) {

	const { sculpture } = props

	return (
		<Link to={ sculpture.sculptureSlug } className="sculptures-list__link">
			<Img className="sculptures-list__image" fluid={ sculpture.sculptureImages[0].imageFile.childImageSharp.fluid } />
			<div className="sculptures-list__overlay">
				<span className="sculptures-list__name">{ sculpture.sculptureName }</span>
			</div>
		</Link>
	);
}

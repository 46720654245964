import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'

import SculpturesList from '../components/SculpturesList/SculpturesList'
import PageHeader from '../components/PageHeader/PageHeader'

const sculptures = ( { data: { strapi: { sculptures } }, location } ) => {

	const trailYear = sculptures.length ? sculptures[ 0 ].sculptureTrail.trailYear : ''

	return (
		<>
			<SEO title={ `Meet the Sculptures ${ trailYear }` } pathname={ location.pathname } />

			<PageHeader heading="Meet the Sculptures" />

			<SculpturesList sculptures={ sculptures } />
		</>
	)
}

export default sculptures

export const query = graphql`
	query sculpturesQuery($id: ID!) {
		strapi {
			sculptures( where: { sculptureTrail: $id }, sort: "sculptureName:ASC" ) {
				id
				sculptureName
				sculptureSlug
				sculptureImages {
					url
					id
					imageFile {
						childImageSharp {
							fluid(maxWidth: 500, maxHeight: 500) {
								...GatsbyImageSharpFluid_withWebp_noBase64
							}
						}
					}
				}
				sculptureTrail {
					trailYear
				}
			}
		}
	}
`